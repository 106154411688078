'use client'

import { useState, Suspense, useEffect, useCallback } from 'react'
import { Loader2 } from 'lucide-react'
import Header from '../components/ui/Header'
import FileUpload from '../components/ui/FileUpload'
import { Button } from "../components/ui/button"
import { Card } from "../components/ui/card"
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { useAuth } from '../lib/hooks/useAuth'
import { InsufficientCreditsModal } from '../components/ui/InsufficientCreditsModal'

interface AnalysisResult {
  match_percentage: number
  matching_skills: string[]
  missing_skills: string[]
  recommendations: string[]
  experience: {
    required: number
    actual: number
  }
  interview_preparation: {
    technical_topics: string[]
    key_points: string[]
    company_research: string[]
    questions_to_prepare: string[]
    projects_to_highlight: string[]
    soft_skills_to_demonstrate: string[]
    salary_negotiation_points: string[]
  }
  recruiter_guidance: {
    technical_questions: string[]
    experience_questions: string[]
    behavioral_questions: string[]
    project_specific_questions: string[]
    skill_verification_questions: string[]
    cultural_fit_assessment: {
      team_compatibility: string[]
      growth_potential: string[]
      red_flags: string[]
    }
    evaluation_criteria: {
      technical_competency: string[]
      experience_validation: string[]
      soft_skills_assessment: string[]
    }
  }
}

function MainContent() {
  const { isLoaded, isSignedIn } = useAuth();
  const [cvFile, setCvFile] = useState<File | null>(null)
  const [jobFile, setJobFile] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)
  const [showAuthPrompt, setShowAuthPrompt] = useState(false)
  const [result, setResult] = useState<AnalysisResult | null>(null)
  const [credits, setCredits] = useState<number>(0)
  const [showInsufficientCreditsModal, setShowInsufficientCreditsModal] = useState(false)

  const saveFilesToStorage = useCallback(async (cv: File, job: File) => {
    const cvBase64 = await fileToBase64(cv);
    const jobBase64 = await fileToBase64(job);

    sessionStorage.setItem('savedCvFile', JSON.stringify({
      name: cv.name,
      type: cv.type,
      content: cvBase64
    }));
    sessionStorage.setItem('savedJobFile', JSON.stringify({
      name: job.name,
      type: job.type,
      content: jobBase64
    }));
  }, []);

  const fileToBase64 = useCallback(async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }, []);

  const base64ToFile = useCallback((base64: string, filename: string, type: string): File => {
    const arr = base64.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type });
  }, []);

  const loadCredits = useCallback(async () => {
    if (!isSignedIn) {
      console.log('User not signed in, skipping credit load')
      return;
    }
    try {
      console.log('Starting to load credits...')
      const response = await fetch('/api/user-credits')
      console.log('API Response status:', response.status)
      const data = await response.json()
      console.log('Credits data received:', data)
      setCredits(data.credits)
      console.log('Credits state updated to:', data.credits)
    } catch (error) {
      console.error('Error loading credits:', error)
    }
  }, [isSignedIn]);

  useEffect(() => {
    console.log('isSignedIn changed:', isSignedIn)
    if (isSignedIn) {
      loadCredits()
    }
  }, [isSignedIn, loadCredits])

  const handleAnalyze = useCallback(async () => {
    if (!cvFile || !jobFile) {
      alert('Please upload both CV and job description');
      return;
    }

    if (!isSignedIn) {
      await saveFilesToStorage(cvFile, jobFile);
      setShowAuthPrompt(true);
      return;
    }

    if (credits <= 0) {
      setShowInsufficientCreditsModal(true)
      return;
    }

    await startAnalysis();
  }, [cvFile, jobFile, isSignedIn, credits, saveFilesToStorage]);

  const startAnalysis = useCallback(async () => {
    if (!cvFile || !jobFile) return;

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('cv', cvFile as Blob);
      formData.append('job_description', jobFile as Blob);

      const response = await fetch('/api/analyze', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Analysis failed');
      }

      const data = await response.json();
      setResult(data);

      // Décrémenter les crédits après une analyse réussie
      const creditsResponse = await fetch('/api/user-credits', {
        method: 'POST'
      });
      const creditsData = await creditsResponse.json();
    } catch (error) {
      console.error('Error:', error);
      alert('Analysis failed. Please try again.');
    } finally {
      setLoading(false);
      setShowAuthPrompt(false);
    }
  }, [cvFile, jobFile]);

  useEffect(() => {
    if (isSignedIn) {
      const savedCv = sessionStorage.getItem('savedCvFile');
      const savedJob = sessionStorage.getItem('savedJobFile');

      if (savedCv && savedJob) {
        const cvData = JSON.parse(savedCv);
        const jobData = JSON.parse(savedJob);

        const cvFile = base64ToFile(cvData.content, cvData.name, cvData.type);
        const jobFile = base64ToFile(jobData.content, jobData.name, jobData.type);

        setCvFile(cvFile);
        setJobFile(jobFile);

        sessionStorage.removeItem('savedCvFile');
        sessionStorage.removeItem('savedJobFile');

        startAnalysis();
      }
    }
  }, [isSignedIn, startAnalysis, base64ToFile]);

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <main className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <Header credits={credits} />
      
      {/* Bannière défilante
      <div className="bg-gradient-to-r from-green-600 via-green-500 to-green-600 text-white overflow-hidden">
        <div className="inline-flex whitespace-nowrap animate-marquee">
          <div className="flex items-center py-2">
            <span className="mx-4">Domain Analyzer.cv is for sale!</span>
            <span className="mx-4">•</span>
            <button
              onClick={() => {
                navigator.clipboard.writeText('badr.tarik@gmail.com');
                alert('Email address copied to clipboard!');
              }}
              className="mx-4 hover:text-white/80 cursor-pointer flex items-center gap-2"
            >
              <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
                <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
              </svg>
              Copy Email
            </button>
            <span className="mx-4">•</span>
            <a 
              href="https://twitter.com/TBR0007"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-4 hover:text-white/80 flex items-center gap-2"
            >
              <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
              </svg>
              Contact on X
            </a>
            <span className="mx-4">•</span>
            <span className="mx-4">Advertise Here: Starting at $100/month</span>
          </div>
        </div>
      </div> */}
      
      
      

      <div className="max-w-5xl mx-auto px-4 py-12">
        {/* Section titre et description */}
        <div className="text-center mb-16">
          <h1 className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-6">
            AI-Powered CV and Job Matching Analysis
          </h1>
          
          {/* Features Cards */}
          <div className="grid md:grid-cols-2 gap-8 mt-12">
            {/* Job Seekers Card */}
            <div className="bg-gradient-to-br from-[#0a66c2]/5 to-[#0a66c2]/10 rounded-2xl p-8 shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1">
              <div className="bg-[#0a66c2] w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6 shadow-lg">
                <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
              <h3 className="text-2xl font-bold text-[#0a66c2] mb-4">For Job Seekers</h3>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-8 h-8 bg-[#0a66c2]/10 rounded-lg flex items-center justify-center">
                    <svg className="w-5 h-5 text-[#0a66c2]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div className="flex-1">
                    <h4 className="font-semibold text-[#0a66c2]">Instant CV Analysis</h4>
                    <p className="text-[#0a66c2]/80">Get detailed feedback on your CV in seconds</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-8 h-8 bg-[#0a66c2]/10 rounded-lg flex items-center justify-center">
                    <svg className="w-5 h-5 text-[#0a66c2]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                    </svg>
                  </div>
                  <div className="flex-1">
                    <h4 className="font-semibold text-[#0a66c2]">Market Value</h4>
                    <p className="text-[#0a66c2]/80">Understand your worth in today's market</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-8 h-8 bg-[#0a66c2]/10 rounded-lg flex items-center justify-center">
                    <svg className="w-5 h-5 text-[#0a66c2]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                    </svg>
                  </div>
                  <div className="flex-1">
                    <h4 className="font-semibold text-[#0a66c2]">Skill Gap Analysis</h4>
                    <p className="text-[#0a66c2]/80">Identify areas for improvement</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Recruiters Card */}
            <div className="bg-gradient-to-br from-[#057642]/5 to-[#057642]/10 rounded-2xl p-8 shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1">
              <div className="bg-[#057642] w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6 shadow-lg">
                <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                </svg>
              </div>
              <h3 className="text-2xl font-bold text-[#057642] mb-4">For Recruiters</h3>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-8 h-8 bg-[#057642]/10 rounded-lg flex items-center justify-center">
                    <svg className="w-5 h-5 text-[#057642]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div className="flex-1">
                    <h4 className="font-semibold text-[#057642]">Time-Saving</h4>
                    <p className="text-[#057642]/80">Screen candidates efficiently</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-8 h-8 bg-[#057642]/10 rounded-lg flex items-center justify-center">
                    <svg className="w-5 h-5 text-[#057642]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                    </svg>
                  </div>
                  <div className="flex-1">
                    <h4 className="font-semibold text-[#057642]">Data-Driven Decisions</h4>
                    <p className="text-[#057642]/80">Make objective hiring choices</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-8 h-8 bg-[#057642]/10 rounded-lg flex items-center justify-center">
                    <svg className="w-5 h-5 text-[#057642]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857m0 0a5 5 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                  </div>
                  <div className="flex-1">
                    <h4 className="font-semibold text-[#057642]">Cultural Fit</h4>
                    <p className="text-[#057642]/80">Assess team compatibility</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Section Upload */}
        <Card className="mb-8 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-blue-100 opacity-50"></div>
          <div className="relative p-8">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold mb-3 text-blue-900">
                Boost Your Career Opportunities
              </h2>
              <p className="text-lg text-blue-700 max-w-2xl mx-auto">
                Get instant AI-powered analysis of your CV against job requirements. 
                Understand your match score, growth potential, and market position.
              </p>
            </div>

            <div className="grid md:grid-cols-2 gap-8">
              {/* CV Upload */}
              <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300">
                <div className="text-center mb-4">
                  <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-3">
                    <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V7a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-semibold text-gray-800 mb-1">Upload Your CV</h3>
                  <p className="text-gray-600 text-sm">PDF, DOC, DOCX, or TXT</p>
                </div>
                <FileUpload
                  label="Upload your CV"
                  fileType="cv"
                  onFileSelect={setCvFile}
                  selectedFile={cvFile}
                  acceptedTypes={['.pdf', '.doc', '.docx']}
                />
                {cvFile && (
                  <div className="mt-4 bg-green-50 p-3 rounded-lg">
                    <p className="text-green-700 flex items-center gap-2">
                      <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      {cvFile.name}
                    </p>
                  </div>
                )}
              </div>

              {/* Job Description Upload */}
              <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300">
                <div className="text-center mb-4">
                  <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-3">
                    <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-semibold text-gray-800 mb-1">Job Description</h3>
                  <p className="text-gray-600 text-sm">PDF, DOC, DOCX, or TXT</p>
                </div>
                <FileUpload
                  label="Upload Job Description"
                  fileType="job"
                  onFileSelect={setJobFile}
                  selectedFile={jobFile}
                  acceptedTypes={['.txt']}
                />
                {jobFile && (
                  <div className="mt-4 bg-green-50 p-3 rounded-lg">
                    <p className="text-green-700 flex items-center gap-2">
                      <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      {jobFile.name}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-center mt-8">
              <Button
                onClick={handleAnalyze}
                className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-lg text-lg font-semibold shadow-lg hover:shadow-xl transition-all duration-300 flex items-center gap-2"
                disabled={loading || !cvFile || !jobFile}
              >
                {loading ? (
                  <>
                    <Loader2 className="w-5 h-5 animate-spin" />
                    Analyzing...
                  </>
                ) : (
                  'Start Analysis'
                )}
              </Button>
            </div>

            <div className="mt-6 text-center">
              <p className="text-gray-500 text-sm">
                Analysis typically takes 30-45 seconds. We'll provide detailed insights about your profile match.
              </p>
            </div>
          </div>
        </Card>

        {/* Loading State */}
        {loading && (
          <div className="text-center py-12">
            <div className="inline-flex items-center justify-center">
              <div className="animate-spin rounded-full h-16 w-16 border-b-4 border-blue-600"></div>
            </div>
            <p className="text-xl font-medium text-gray-700 mt-4">
              Analyzing your documents...
            </p>
            <p className="text-gray-500">This might take a few moments</p>
          </div>
        )}

        {/* Results Section */}
        {result && (
          <Card className="mt-8 p-8">
            <h2 className="text-2xl font-bold mb-6">Analysis Results</h2>
            
            {/* Match Score and Experience */}
            <div className="flex justify-center mb-8">
              {/* Match Score Chart */}
              <div className="bg-white rounded-xl p-6 shadow-sm w-[400px]">
                <h3 className="text-xl font-semibold text-gray-800 mb-4 text-center">
                  Profile Match Score
                </h3>
                <div className="h-[200px] w-full">
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={[
                          { name: 'Match', value: result?.match_percentage },
                          { name: 'Gap', value: 100 - (result?.match_percentage || 0) }
                        ]}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        startAngle={90}
                        endAngle={-270}
                        dataKey="value"
                      >
                        <Cell fill="#3B82F6" />
                        <Cell fill="#E5E7EB" />
                      </Pie>
                      <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        className="text-2xl font-bold"
                        fill="#1F2937"
                      >
                        {result?.match_percentage}% 
                      </text>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            {/* Skills Analysis */}
            <div className="grid md:grid-cols-2 gap-8">
              {/* Matching Skills */}
              <div className="bg-green-50 rounded-xl p-6">
                <h3 className="text-lg font-semibold mb-4 text-green-800 flex items-center gap-2">
                  <span>✓</span> Matching Skills
                </h3>
                <ul className="space-y-2">
                  {result?.matching_skills?.map((skill, index) => (
                    <li key={index} className="text-green-700 flex items-center gap-2">
                      <span>•</span> {skill}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Missing Skills */}
              <div className="bg-red-50 rounded-xl p-6">
                <h3 className="text-lg font-semibold mb-4 text-red-800 flex items-center gap-2">
                  <span>!</span> Missing Skills
                </h3>
                <ul className="space-y-2">
                  {result?.missing_skills?.map((skill, index) => (
                    <li key={index} className="text-red-700 flex items-center gap-2">
                      <span>•</span> {skill}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Recommendations */}
            <div className="mt-8 bg-blue-50 rounded-xl p-6">
              <h3 className="text-lg font-semibold mb-4 text-blue-800">
                Recommendations
              </h3>
              <ul className="space-y-2">
                {result?.recommendations?.map((recommendation, index) => (
                  <li key={index} className="text-blue-700 flex items-center gap-2">
                    <span>•</span> {recommendation}
                  </li>
                ))}
              </ul>
            </div>

            {/* Two-column layout for results */}
            <div className="grid md:grid-cols-2 gap-8 mt-8">
              {/* Left Column - Job Seeker Guide (Blue) */}
              <div className="bg-blue-50 rounded-xl p-6">
                <h3 className="text-xl font-semibold mb-6 text-blue-800">
                  Job Seeker Guide
                </h3>
                <div className="grid gap-6">
                  <div>
                    <h4 className="font-medium text-blue-700 mb-2">Technical Topics to Review</h4>
                    <div className="flex flex-wrap gap-2">
                      {result?.interview_preparation?.technical_topics?.map((topic, index) => (
                        <span key={index} className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm">
                          {topic}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div>
                    <h4 className="font-medium text-blue-700 mb-2">Key Points to Emphasize</h4>
                    <ul className="space-y-2">
                      {result?.interview_preparation?.key_points?.map((point, index) => (
                        <li key={index} className="text-blue-600 flex items-center gap-2">
                          <svg className="w-5 h-5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          {point}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-medium text-blue-700 mb-2">Company Research</h4>
                    <ul className="space-y-2">
                      {result?.interview_preparation?.company_research?.map((point, index) => (
                        <li key={index} className="text-blue-600 flex items-center gap-2">
                          <svg className="w-5 h-5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          {point}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-medium text-blue-700 mb-2">Questions to Prepare</h4>
                    <ul className="space-y-2">
                      {result?.interview_preparation?.questions_to_prepare?.map((question, index) => (
                        <li key={index} className="text-blue-600 flex items-center gap-2">
                          <svg className="w-5 h-5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          {question}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-medium text-blue-700 mb-2">Projects to Highlight</h4>
                    <ul className="space-y-2">
                      {result?.interview_preparation?.projects_to_highlight?.map((project, index) => (
                        <li key={index} className="text-blue-600 flex items-center gap-2">
                          <svg className="w-5 h-5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                          </svg>
                          {project}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-medium text-blue-700 mb-2">Soft Skills to Demonstrate</h4>
                    <div className="flex flex-wrap gap-2">
                      {result?.interview_preparation?.soft_skills_to_demonstrate?.map((skill, index) => (
                        <span key={index} className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm">
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div>
                    <h4 className="font-medium text-blue-700 mb-2">Salary Negotiation Points</h4>
                    <ul className="space-y-2">
                      {result?.interview_preparation?.salary_negotiation_points?.map((point, index) => (
                        <li key={index} className="text-blue-600 flex items-center gap-2">
                          <svg className="w-5 h-5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          {point}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              {/* Right Column - Recruiter Guide (Green) */}
              <div className="bg-green-50 rounded-xl p-6">
                <h3 className="text-xl font-semibold mb-6 text-green-800">
                  Recruiter Guide
                </h3>
                <div className="grid gap-8">
                  <div>
                    <h4 className="font-medium text-green-700 mb-4">Technical Assessment</h4>
                    <ul className="space-y-3">
                      {result?.recruiter_guidance?.technical_questions?.map((question, index) => (
                        <li key={index} className="bg-white p-4 rounded-lg text-green-700 shadow-sm">
                          {question}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-medium text-green-700 mb-4">Experience Validation</h4>
                    <ul className="space-y-3">
                      {result?.recruiter_guidance?.experience_questions?.map((question, index) => (
                        <li key={index} className="bg-white p-4 rounded-lg text-green-700 shadow-sm">
                          {question}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-medium text-green-700 mb-4">Behavioral Questions</h4>
                    <ul className="space-y-3">
                      {result?.recruiter_guidance?.behavioral_questions?.map((question, index) => (
                        <li key={index} className="bg-white p-4 rounded-lg text-green-700 shadow-sm">
                          {question}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-medium text-green-700 mb-4">Cultural Fit Assessment</h4>
                    <div className="grid gap-4">
                      <div className="bg-white p-4 rounded-lg">
                        <h5 className="font-medium text-green-700 mb-2">Team Compatibility</h5>
                        <ul className="space-y-2">
                          {result?.recruiter_guidance?.cultural_fit_assessment?.team_compatibility?.map((point, index) => (
                            <li key={index} className="text-green-600 flex items-center gap-2">
                              <svg className="w-5 h-5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>
                              {point}
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="bg-white p-4 rounded-lg">
                        <h5 className="font-medium text-green-700 mb-2">Growth Potential</h5>
                        <ul className="space-y-2">
                          {result?.recruiter_guidance?.cultural_fit_assessment?.growth_potential?.map((point, index) => (
                            <li key={index} className="text-green-600 flex items-center gap-2">
                              <svg className="w-5 h-5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                              </svg>
                              {point}
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="bg-white p-4 rounded-lg">
                        <h5 className="font-medium text-red-700 mb-2">Areas to Verify</h5>
                        <ul className="space-y-2">
                          {result?.recruiter_guidance?.cultural_fit_assessment?.red_flags?.map((point, index) => (
                            <li key={index} className="text-red-600 flex items-center gap-2">
                              <svg className="w-5 h-5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                              </svg>
                              {point}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h4 className="font-medium text-green-700 mb-4">Evaluation Criteria</h4>
                    <div className="grid gap-4">
                      <div className="bg-white p-4 rounded-lg">
                        <h5 className="font-medium text-green-700 mb-2">Technical Competency</h5>
                        <ul className="space-y-2">
                          {result?.recruiter_guidance?.evaluation_criteria?.technical_competency?.map((point, index) => (
                            <li key={index} className="text-green-600 flex items-center gap-2">
                              <svg className="w-5 h-5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                              {point}
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="bg-white p-4 rounded-lg">
                        <h5 className="font-medium text-green-700 mb-2">Experience Validation</h5>
                        <ul className="space-y-2">
                          {result?.recruiter_guidance?.evaluation_criteria?.experience_validation?.map((point, index) => (
                            <li key={index} className="text-green-600 flex items-center gap-2">
                              <svg className="w-5 h-5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                              </svg>
                              {point}
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="bg-white p-4 rounded-lg">
                        <h5 className="font-medium text-green-700 mb-2">Soft Skills Assessment</h5>
                        <ul className="space-y-2">
                          {result?.recruiter_guidance?.evaluation_criteria?.soft_skills_assessment?.map((point, index) => (
                            <li key={index} className="text-green-600 flex items-center gap-2">
                              <svg className="w-5 h-5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                              </svg>
                              {point}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )}

        {/* Enterprise Section */}
        <div className="mt-12 bg-blue-900 text-white rounded-xl p-8">
          <h3 className="text-2xl font-bold mb-4 text-center">Need Enterprise-Level Analysis?</h3>
          <p className="text-lg text-center mb-8">
            Want to automate CV analysis for large-scale recruitment? Our enterprise solutions can help streamline your hiring process.
          </p>
          <div className="flex justify-center gap-4">
            <button
              onClick={() => window.open('https://twitter.com/TBR0007', '_blank')}
              className="bg-blue-800 hover:bg-blue-700 px-6 py-2 rounded-lg flex items-center gap-2"
            >
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
              </svg>
              Contact on X
            </button>
            <button
              onClick={() => {
                navigator.clipboard.writeText('badr.tarik@gmail.com');
                alert('Email address copied to clipboard!');
              }}
              className="bg-blue-800 hover:bg-blue-700 px-6 py-2 rounded-lg flex items-center gap-2"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              Copy Email
            </button>
          </div>
        </div>
      </div>
      <InsufficientCreditsModal 
      isOpen={showInsufficientCreditsModal} 
      onClose={() => setShowInsufficientCreditsModal(false)} 
    />
    </main>
  )
}

export default function Page() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <MainContent />
    </Suspense>
  )
}