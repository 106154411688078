'use client'

import { useEffect, useState } from 'react'
import { useUser, useAuth as useClerkAuth } from '@clerk/nextjs'
import { supabase } from '../../lib/supabase'

export function useAuth() {
  const { isLoaded, isSignedIn, user } = useUser()
  const { getToken } = useClerkAuth()

  const syncUserToSupabase = async () => {
    if (!isLoaded || !isSignedIn) {
      return
    }

    try {
      await getToken({ template: 'supabase' })
      const { data: { user: supabaseUser }, error } = await supabase.auth.getUser()

      if (error || !supabaseUser) {
        // Si l'utilisateur n'existe pas dans Supabase, créez-le
        const { error: createError } = await supabase
          .from('profiles')
          .upsert({
            id: user.id,
            email: user.emailAddresses[0].emailAddress,
            full_name: `${user.firstName} ${user.lastName}`,
            avatar_url: user.imageUrl,
            updated_at: new Date().toISOString(),
            created_at: new Date().toISOString()
          })
          .select()
          .single()

        if (createError) {
          console.error('Error creating user in Supabase:', createError)
        }
      }
    } catch (error) {
      console.error('Error syncing user to Supabase:', error)
    }
  }

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      syncUserToSupabase()
    }
  }, [isLoaded, isSignedIn])

  return { isLoaded, isSignedIn, user }
} 