'use client'

import Link from 'next/link'
import { UserButton, SignInButton, SignUpButton, useAuth } from "@clerk/nextjs"
import { Button } from "./button"
import { CreditBadge } from "./CreditBadge"
import { useState, useEffect } from 'react'
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/solid'

interface HeaderProps {
  credits?: number
}

const Header = ({ credits: propCredits }: HeaderProps) => {
  const { isSignedIn } = useAuth()
  const [credits, setCredits] = useState<number | undefined>(propCredits)
  const [isLoading, setIsLoading] = useState(true)
  const [showWidget, setShowWidget] = useState(true)
  const [showAfterDelay, setShowAfterDelay] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  useEffect(() => {
    if (isSignedIn) {
      const fetchCredits = async () => {
        try {
          const response = await fetch('/api/user-credits')
          const data = await response.json()
          setCredits(data.credits)
        } catch (error) {
          console.error('Error fetching credits:', error)
        } finally {
          setIsLoading(false)
        }
      }

      fetchCredits()
      const interval = setInterval(fetchCredits, 30000)
      return () => clearInterval(interval)
    } else {
      setIsLoading(false)
    }
  }, [isSignedIn])

  useEffect(() => {
    if (propCredits !== undefined) {
      setCredits(propCredits)
      setIsLoading(false)
    }
  }, [propCredits])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAfterDelay(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  return (
    <>
      <header className="bg-gradient-to-r from-blue-700 via-blue-600 to-blue-700 text-white py-4 shadow-lg relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <Link href="/" className="text-2xl font-bold text-white hover:text-blue-100">
                Analyzer.CV
              </Link>
            </div>
            
            {/* Desktop Menu */}
            <div className="hidden sm:flex items-center justify-center flex-1 space-x-8">
              <Link href="/pricing" className="text-white hover:text-blue-100 px-3 py-2 rounded-md text-sm font-medium">
                Pricing
              </Link>
              <Link href="/reviews" className="text-white hover:text-blue-100 px-3 py-2 rounded-md text-sm font-medium">
                Reviews
              </Link>
            </div>

            <div className="hidden sm:flex items-center space-x-4">
              {!isSignedIn ? (
                <>
                  <SignInButton mode="modal">
                    <Button variant="ghost" className="text-white hover:text-blue-100">
                      Sign In
                    </Button>
                  </SignInButton>
                  <SignUpButton mode="modal">
                    <Button className="bg-white text-blue-600 hover:bg-blue-50">
                      Get Started
                    </Button>
                  </SignUpButton>
                </>
              ) : (
                <div className="flex items-center gap-4">
                  {!isLoading && credits !== undefined && (
                    <div className="animate-fadeIn">
                      <CreditBadge credits={credits} />
                    </div>
                  )}
                  <UserButton 
                    afterSignOutUrl="/"
                    appearance={{
                      elements: {
                        avatarBox: "w-10 h-10"
                      }
                    }}
                  />
                </div>
              )}
            </div>
            
            {/* Mobile Menu Button */}
            <div className="flex sm:hidden items-center space-x-2">
              {isSignedIn && !isLoading && credits !== undefined && (
                <div className="animate-fadeIn">
                  <CreditBadge credits={credits} />
                </div>
              )}
              <button
                onClick={toggleMobileMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-blue-100 hover:bg-blue-600 focus:outline-none"
              >
                {isMobileMenuOpen ? (
                  <XMarkIcon className="block h-6 w-6" />
                ) : (
                  <Bars3Icon className="block h-6 w-6" />
                )}
              </button>
              {isSignedIn && (
                <UserButton 
                  afterSignOutUrl="/"
                  appearance={{
                    elements: {
                      avatarBox: "w-10 h-10"
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="sm:hidden absolute top-full left-0 right-0 bg-blue-700 shadow-lg">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link 
                href="/pricing" 
                className="block text-white hover:bg-blue-600 px-3 py-2 rounded-md text-base font-medium"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Pricing
              </Link>
              <Link 
                href="/reviews" 
                className="block text-white hover:bg-blue-600 px-3 py-2 rounded-md text-base font-medium"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Reviews
              </Link>
              {!isSignedIn && (
                <div className="space-y-2 pt-2">
                  <SignInButton mode="modal">
                    <Button variant="ghost" className="w-full text-white hover:text-blue-100">
                      Sign In
                    </Button>
                  </SignInButton>
                  <SignUpButton mode="modal">
                    <Button className="w-full bg-white text-blue-600 hover:bg-blue-50">
                      Get Started
                    </Button>
                  </SignUpButton>
                </div>
              )}
            </div>
          </div>
        )}
      </header>
    </>
  )
}

export default Header