'use client'

import { ChangeEvent, useRef, useState, DragEvent } from 'react'
import { Button } from './button'
import { Upload, FileText, X } from 'lucide-react'

interface FileUploadProps {
  label: string
  fileType: 'cv' | 'job'
  onFileSelect: (file: File | null) => void
  selectedFile: File | null
  acceptedTypes?: string[]
}

export default function FileUpload({ label, fileType, onFileSelect, selectedFile, acceptedTypes = ['.pdf', '.doc', '.docx'] }: FileUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isDragging, setIsDragging] = useState(false)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      validateAndSelectFile(file)
    }
  }

  const validateAndSelectFile = (file: File) => {
    const validTypes = acceptedTypes || ['.pdf', '.doc', '.docx', '.txt']
    const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`
    
    if (!validTypes.includes(fileExtension)) {
      alert('Please upload a valid file type')
      return
    }
    
    onFileSelect(file)
  }

  const handleRemoveFile = (e: React.MouseEvent) => {
    e.stopPropagation() // Empêche le déclenchement du click du bouton parent
    onFileSelect(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
    
    const file = e.dataTransfer.files[0]
    if (file) {
      validateAndSelectFile(file)
    }
  }

  return (
    <div
      className={`
        relative w-full min-h-[200px] 
        border-2 border-dashed rounded-xl
        ${isDragging 
          ? 'border-blue-500 bg-blue-50' 
          : selectedFile 
            ? 'border-green-500 bg-green-50' 
            : 'border-gray-300 hover:border-gray-400'
        }
        transition-all duration-200
      `}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {selectedFile && (
        <button
          onClick={handleRemoveFile}
          className="absolute top-2 right-2 p-1 rounded-full bg-red-100 hover:bg-red-200 text-red-600 transition-colors"
          title="Remove file"
        >
          <X className="w-5 h-5" />
        </button>
      )}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept={acceptedTypes?.join(',') || '.pdf,.doc,.docx,.txt'}
        className="hidden"
        aria-label={`Upload ${fileType.toUpperCase()}`}
      />
      <Button 
        onClick={() => fileInputRef.current?.click()}
        variant="ghost"
        className="w-full h-full min-h-[200px] rounded-xl hover:bg-gray-50/50"
      >
        <div className="flex flex-col items-center space-y-4 px-4 py-6">
          {isDragging ? (
            <>
              <Upload className="w-10 h-10 text-blue-500 animate-bounce" />
              <div className="text-center">
                <p className="text-blue-500 font-medium">Drop your file here</p>
              </div>
            </>
          ) : selectedFile ? (
            <>
              <FileText className="w-10 h-10 text-green-500" />
              <div className="text-center">
                <p className="text-green-600 font-medium mb-1">{selectedFile.name}</p>
                <p className="text-sm text-green-500">
                  File selected - Click to change
                </p>
              </div>
            </>
          ) : (
            <>
              <FileText className="w-10 h-10 text-gray-400" />
              <div className="text-center">
                <p className="text-gray-600 font-medium mb-2">{label}</p>
                <p className="text-sm text-gray-500">
                  Drag & drop or click to upload
                </p>
                <p className="text-xs text-gray-400 mt-2">
                  Supported formats: {acceptedTypes?.join(', ') || 'PDF, DOC, DOCX, TXT'}
                </p>
              </div>
            </>
          )}
        </div>
      </Button>
    </div>
  )
} 